/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import GroupWave from "../icons/GroupWave";
import { useLayoutEffect, useState } from "react";
const Animation = () => {
  const [loaded, setLoaded] = useState(false);
  useLayoutEffect(() => {
    setLoaded(true);
  });

  return (
    <div
      css={css({
        marginTop: "-300px",
        "@media (max-width:768px)": {
          marginBottom: "0",
          marginTop: "0",
        },
      })}
    >
      <GroupWave
        css={css({
          width: "100%",
          marginBottom: "-50px",

          height: "auto",
          "@media (max-width:768px)": {
            marginBottom: "-10px",
            marginTop: "175px",
          },
          path: {
            transform: loaded ? "translateY(0)" : "translateY(50%)",
            transition: "2s cubic-bezier(0.83, 0, 0.17, 1)",
          },
        })}
      />
    </div>
  );
};
export default Animation;

import React from "react";

function Phone(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 42 42"
      {...props}
    >
      <rect
        width="21.293"
        height="30.906"
        x="10.353"
        y="5.547"
        stroke="#006180"
        strokeWidth="1.5"
        rx="5.25"
      ></rect>
      <circle cx="21" cy="29.389" r="2.554" fill="#006180"></circle>
    </svg>
  );
}

export default Phone;

/** @jsxImportSource @emotion/react */
import { colors } from "../theme";
import { css } from "@emotion/react";
import Deal from "../icons/Deal";
import { InputField } from "./InputField";
import Phone from "../icons/Phone";
import Mail from "../icons/Mail";
import Book from "../icons/Book";
import { handleCalendry } from "../utils/Calendry";
import "../App.css";
const ContactUs = () => {
  return (
    <div
      id="contacts"
      className="contacts"
      css={css({
        display: "flex",
        justifyContent: "space-between",
        padding: "40px 24px 0",
        backgroundColor: colors.background,
        alignItems: "center",
        width: "100%",
        marginTop: "-110px",
        paddingTop: "110px",
        "@media (max-width:768px)": {
          flexDirection: "column",
        },
      })}
    >
      <Deal
        css={css({
          paddingLeft: "40px",
          maxWidth: "50%",
          height: "auto",
          width: "600px",
          "@media (max-width:768px)": {
            maxWidth: "85%",
            padding: "0",
          },
        })}
      />
      <div
        css={css({
          maxWidth: "50%",

          "@media (max-width:768px)": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "32px",
            maxWidth: "85%",
          },
        })}
      >
        <h4
          css={css({
            fontSize: "32px",
            fontWeight: "normal",
            marginBottom: "14px",
            "@media (max-width:768px)": {
              fontSize: "24px",
              marginBottom: "16px",
            },
          })}
        >
          Contact us for any question :)
        </h4>
        <p style={{ fontSize: "20px", fontWeight: "normal" }}>
          Book an online meeting and let’s discuss about possibilities of your
          ideas in the internet world.
        </p>
        <form
          css={css({
            display: "flex",
            flexDirection: "column",
            width: "330px",
            alignItems: "center",
            paddingTop: "25px",
            "@media (max-width:768px)": {},
          })}
          method="post"
        >
          <InputField icon={<Phone />} placeholder="+372 5310 8632" />
          <InputField icon={<Mail />} placeholder="info@visionforge.ee" />
          <InputField icon={<Book />} placeholder="Book a Meeting">
            <button
              className="btn"
              onClick={handleCalendry()}
              css={css({
                backgroundColor: colors.primary,
                color: colors.whiteText,
                width: "75px",
                height: "auto",
                padding: "9px 0px",
                borderRadius: "8px",
                position: "absolute",
                right: "8px",
                top: "8px",
                boxShadow: "none",
                border: "none",
                transition: "background-color 0.2s ease-out",
                ":hover": {
                  backgroundColor: colors.dark,
                },
                "@media (max-width:768px)": {
                  top: "8px",
                },
              })}
            >
              Book
            </button>
          </InputField>
        </form>
      </div>
    </div>
  );
};
export default ContactUs;

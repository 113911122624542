import React from "react";

function Book(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 42 42"
      {...props}
    >
      <rect
        width="26.891"
        height="26.491"
        x="7.555"
        y="7.755"
        stroke="#006180"
        strokeWidth="1.5"
        rx="5.25"
      ></rect>
      <path
        stroke="#006180"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M7.673 16.253h26.339M14.313 7.732V4.219M27.372 7.732V4.219"
      ></path>
    </svg>
  );
}

export default Book;

import React from "react";

function Mail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 42 42"
      {...props}
    >
      <rect
        width="30.195"
        height="24.523"
        x="5.902"
        y="8.739"
        stroke="#006180"
        strokeWidth="1.5"
        rx="5.25"
      ></rect>
      <path
        stroke="#006180"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M10.517 13.508l8.358 8.39a3 3 0 004.25 0l8.358-8.39M10.517 28.639l5.241-5.262m15.725 5.262l-5.242-5.262"
      ></path>
    </svg>
  );
}

export default Mail;

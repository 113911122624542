export const colors = {
  primary: "rgba(0, 97, 128, 1)",
  light: "rgba(0, 126, 166, 1)",
  lighter: "rgba(161, 213, 229, 1)",
  dark: "rgba(0, 78, 102, 1)",
  darker: "rgba(10, 41, 51, 1)",
  background: "rgba(242, 248, 250, 1)",
  whiteText: "rgba(255, 255, 255, 1)",
  shadow: "#012b38",
  darkText: "rgba(107, 142, 153, 1)",
};
export const boxStyle = {
  //...
  marginTop: "30px",

  color: colors.whiteText,
  borderColor: "white",
  borderRadius: "8px",
  borderWidth: "1px",
  textAlign: "center",
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingTop: "8px",
  paddingBottom: "8px",
  backgroundColor: colors.primary,
  width: "250px",
  height: "46px",
  cursor: "pointer",
  fontWeight: "light",

  borderStyle: "solid",
  transition: "background-color 0.2s ease-out",
  ":hover": { backgroundColor: colors.dark, borderColor: colors.primary },
};

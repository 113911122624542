/** @jsxImportSource @emotion/react */
import { colors } from "../theme";
import { css } from "@emotion/react";
import "../App.css";
import Nav from "./Nav";
import Services from "./Services";
import ServicesIcon from "../icons/services";
import Contacts from "../icons/contacts";
import Meet from "../icons/Meet";
import { handleCalendry } from "../utils/Calendry";
import Icon from "../icons/design";
import Dev from "../icons/dev";
import Evolution from "../icons/Evolution";
import Security from "../icons/Security";
import Seo from "../icons/Seo";
import Support from "../icons/Script support";
const Main = () => {
  return (
    <main
      id="services"
      className="services"
      css={css({
        backgroundColor: colors.primary,
        paddingTop: "90px",
        paddingBottom: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        "@media (max-width:768px)": { paddingTop: "75px" },
      })}
    >
      <div
        css={css({
          display: "flex",
          flexDirection: "column",
          backgroundColor: colors.dark,
          width: "88px",
          height: "312px",
          top: "50%",
          transform: "translateY(-50%)",
          paddingTop: "2rem",
          paddingBottom: "2rem",
          marginLeft: "35px",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          borderRadius: "40px",
          left: "0",
          zIndex: "1",
          boxShadow: "1px 2px 9px rgb(48, 48, 47)",
          "@media (max-width:768px)": {
            width: "80%",
            height: "80px",
            marginLeft: "0",
            marginTop: "10px",
            paddingTop: "0.2rem",
            paddingBottom: "0.8rem",
            paddingRight: "28px",
            paddingLeft: "28px",
            bottom: "40px",
            gap: "20px",
            left: "50%",
            top: "auto",
            transform: "translateX(-50%)",
            flexDirection: "row",
            position: "fixed",
          },
        })}
      >
        <Nav icon={<ServicesIcon />} title="Services" link="services" />

        <Nav icon={<Contacts />} title="Contact" link="contacts" />
        <Nav
          icon={<Meet />}
          title="Meeting"
          onClick={handleCalendry()}
          lastItem
        />
      </div>

      <h3
        css={css({
          color: colors.whiteText,
          textAlign: "center",
          fontWeight: "semi-bold",
          fontSize: "52px",
          paddingBottom: "28px",
          marginTop: "24px",
          textShadow: "2px 2px black",
          "@media (max-width:768px)": {
            fontSize: "36px",
          },
        })}
      >
        Our Services
      </h3>

      <div
        css={css({
          display: "flex",
          flexWrap: "wrap",
          maxWidth: "100%",
          width: "1160px",
          alignItems: "center",

          justifyContent: "space-between",
          "@media (max-width:768px)": {
            flexDirection: "column",
          },
        })}
      >
        <Services
          calendlyPath="/design"
          icon={<Icon />}
          title="Design"
          items={[
            "User Interface (UI) Design",
            "User Experience (UX) Design",
            "Graphic design",
            "Motion graphic design",
          ]}
        />
        <Services
          calendlyPath="/development"
          icon={<Dev />}
          title="Development"
          items={["Web Portals", "Web Apps", "SaaS"]}
        />
        <Services
          calendlyPath="/design"
          icon={<Evolution />}
          title="Evolution"
          items={["Product Testing", "User Analyze", "Targeting product users"]}
        />
        <Services
          icon={<Security />}
          calendlyPath="/security"
          title="Security"
          items={["Web & Server Security Hardening", "Web Penetration Testing"]}
        />
        <Services
          calendlyPath="/digital-marketing"
          icon={<Seo />}
          title="SEO"
          items={[
            "SEO and ASO Optimization",
            "Content Strategy & Planning",
            "Social Media Marketing",
          ]}
        />
        <Services
          calendlyPath="/unspecified-category"
          icon={<Support />}
          title="Support"
          items={["Python", "PHP", "JavaScript", "Need an Custom Script?"]}
        />
      </div>
    </main>
  );
};
export default Main;

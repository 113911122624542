import React from "react";

function Seo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 320 321"
      {...props}
    >
      <g clipPath="url(#clip0_24_600)">
        <path
          fill="#fff"
          d="M0 21.58C0 9.796 9.551.245 21.333.245h277.334C310.449.246 320 9.797 320 21.58v277.334c0 11.782-9.551 21.333-21.333 21.333H21.333C9.551 320.246 0 310.695 0 298.913V21.579z"
        ></path>
        <g filter="url(#filter0_d_24_600)">
          <rect
            width="343.171"
            height="305.176"
            x="329.067"
            y="356.38"
            fill="#628000"
            fillOpacity="0.3"
            rx="11.144"
            shapeRendering="crispEdges"
            transform="rotate(180 329.067 356.38)"
          ></rect>
        </g>
        <g filter="url(#filter1_d_24_600)">
          <rect
            width="282.411"
            height="282.411"
            x="432.278"
            y="192.41"
            fill="#800062"
            fillOpacity="0.3"
            rx="119.107"
            shapeRendering="crispEdges"
            transform="rotate(180 432.278 192.41)"
          ></rect>
        </g>
        <g filter="url(#filter2_d_24_600)">
          <rect
            width="323.203"
            height="323.203"
            x="238.046"
            y="203.693"
            fill="#FF6119"
            fillOpacity="0.3"
            rx="124.858"
            shapeRendering="crispEdges"
            transform="rotate(180 238.046 203.693)"
          ></rect>
        </g>
        <g filter="url(#filter3_d_24_600)">
          <path
            fill="#428000"
            d="M78.854 208.216l-101.279 92.668c-2.96 2.709-2.621 7.469.692 9.73l61.615 42.054a6.222 6.222 0 008.837-1.928l70.449-116.906c2.17-3.601 7.212-4.058 9.994-.906l18.506 20.97c3.449 3.907 9.886 2.097 10.793-3.035l20.757-117.449c.837-4.736-3.786-8.591-8.295-6.915l-128.164 47.63c-5.455 2.027-5.389 9.767.1 11.701l33.861 11.927c4.434 1.562 5.601 7.286 2.134 10.459z"
          ></path>
        </g>
        <g filter="url(#filter4_d_24_600)">
          <path
            fill="#800062"
            fillRule="evenodd"
            d="M207.764 72.152c0-18.52 15.014-33.534 33.534-33.534s33.533 15.014 33.533 33.534-15.013 33.534-33.533 33.534c-18.52 0-33.534-15.014-33.534-33.534zm62.149 43.185c-8.198 5.444-18.036 8.615-28.615 8.615-28.608 0-51.8-23.192-51.8-51.8 0-28.608 23.192-51.8 51.8-51.8 28.608 0 51.799 23.192 51.799 51.8 0 10.962-3.404 21.128-9.214 29.499l20.103 20.103-13.828 13.828-20.245-20.245z"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_24_600"
          width="346.726"
          height="311.398"
          x="-14.104"
          y="51.204"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_24_600"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_24_600"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_24_600"
          width="285.967"
          height="288.634"
          x="149.867"
          y="-90.002"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_24_600"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_24_600"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_d_24_600"
          width="326.759"
          height="329.425"
          x="-85.157"
          y="-119.51"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_24_600"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_24_600"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter3_d_24_600"
          width="207.32"
          height="231.208"
          x="-24.447"
          y="126.1"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_24_600"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_24_600"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter4_d_24_600"
          width="118.044"
          height="118.785"
          x="189.498"
          y="20.352"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="1.778"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_24_600"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_24_600"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_24_600">
          <path
            fill="#fff"
            d="M0 21.58C0 9.796 9.551.245 21.333.245h277.334C310.449.246 320 9.797 320 21.58v277.334c0 11.782-9.551 21.333-21.333 21.333H21.333C9.551 320.246 0 310.695 0 298.913V21.579z"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Seo;

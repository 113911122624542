import { colors } from "../theme";
const MyFooter = () => {
  return (
    <footer>
      <div
        style={{
          backgroundColor: colors.primary,
          paddingTop: "22px",
          paddingBottom: "22px",
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            color: colors.whiteText,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
            fontWeight: "normal",
          }}
        >
      © VisionForge All Rights Reserved 
        </p>
      </div>
    </footer>
  );
};
export default MyFooter;

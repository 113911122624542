import React from "react";

function Meet(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="43"
      fill="none"
      viewBox="0 0 42 43"
      {...props}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M22.152 12.302c-.995-.17-2.395-.172-4.785-.172h-.579c-2.384 0-3.905.002-5.018.174-1.056.163-1.68.47-2.28 1.07-.57.57-.897 1.341-1.069 2.62-.174 1.3-.176 3.008-.176 5.386v.58c0 2.377.002 4.086.176 5.386.172 1.278.5 2.049 1.069 2.618.6.6 1.224.908 2.28 1.07 1.114.173 2.634.175 5.018.175h.58c2.389 0 3.789-.003 4.784-.172.914-.156 1.442-.446 2.069-1.073.698-.698 1.03-1.696 1.161-3.555a.75.75 0 011.206-.541c1.598 1.231 2.758 2.105 3.685 2.629.931.526 1.463.601 1.86.486a2.25 2.25 0 00.7-.343c.352-.258.626-.765.774-1.944.147-1.167.148-2.78.148-5.027 0-2.247-.001-3.86-.148-5.026-.148-1.18-.422-1.686-.775-1.944a2.25 2.25 0 00-.7-.344c-.396-.114-.928-.04-1.86.487-.926.523-2.086 1.397-3.683 2.629a.75.75 0 01-1.207-.541c-.131-1.86-.463-2.857-1.161-3.555-.627-.627-1.155-.917-2.07-1.073zm.252-1.478c1.233.21 2.04.652 2.878 1.49.826.827 1.236 1.864 1.447 3.16 1.091-.828 2.01-1.489 2.806-1.938 1.035-.585 2.024-.909 3.016-.621.419.122.813.315 1.166.573.878.641 1.219 1.696 1.379 2.968.16 1.272.16 2.979.16 5.156v.115c0 2.177 0 3.884-.16 5.156-.16 1.271-.501 2.327-1.38 2.968a3.747 3.747 0 01-1.165.573c-.992.288-1.98-.036-3.016-.621-.795-.45-1.714-1.11-2.806-1.938-.21 1.296-.62 2.333-1.447 3.16-.838.838-1.645 1.28-2.878 1.49-1.137.194-2.67.194-4.942.194h-.75c-2.29 0-3.93 0-5.171-.192-1.314-.203-2.248-.628-3.112-1.492-.895-.895-1.3-2.035-1.494-3.48-.19-1.411-.19-3.22-.19-5.529v-.693c0-2.309 0-4.118.19-5.53.194-1.444.6-2.584 1.494-3.479.864-.864 1.798-1.29 3.112-1.492 1.242-.192 2.88-.192 5.17-.192h.751c2.273 0 3.805 0 4.942.194z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Meet;

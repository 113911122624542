import React from "react";

function WaveSecond(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1923"
      height="178"
      fill="none"
      viewBox="0 0 1923 178"
      {...props}
    >
      <path
        fill="#006180"
        d="M1837.39 119.61l27.51-10.467A241.328 241.328 0 001990.45 0H-67.451c0 28.512 19.084 53.496 46.592 60.997l134.126 36.57a245.222 245.222 0 0089.644 7.343l350.695-36.14a328.962 328.962 0 01216.412 53.659 328.953 328.953 0 00255.952 47.13L1326 101.017a483.402 483.402 0 01249.53 9.144l53.17 16.321a321.456 321.456 0 00208.69-6.872z"
      ></path>
    </svg>
  );
}

export default WaveSecond;

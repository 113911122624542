/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { colors } from "../theme";
import { boxStyle } from "../theme";
import { handleCalendry } from "../utils/Calendry";
import "../App.css";

const Services = (props) => {
  return (
    <div
      id="box"
      className="box"
      css={css({
        display: "flex",
        flexDirection: "column",
        marginBottom: "4rem",
        justifyContent: "space-between",

        "@media (max-width:768px)": {
          alignItems: "center",
        },
      })}
    >
      <div
        id="inner_box"
        className="inner_box"
        css={css({
          width: "auto",
          height: "auto",
          paddingBottom: "10px",

          "@media (max-width:768px)": {
            width: "100%",
          },
          svg: {
            width: "100%",
            height: "auto",
            boxShadow: "4px 4px 2px 6px rgba(21, 45, 46, 0.98)",

            transition: "box-shadow 0.2s ",
            borderRadius: "22px",
            ":hover": { boxShadow: "none", border: "none" },
          },
        })}
      >
        {props.icon}
      </div>
      <h2
        css={css({
          color: colors.whiteText,
          fontWeight: "normal",
          fontSize: "28px",
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          paddingBottom: "15px",

          justifyContent: "center",
          "@media (max-width:768px)": {
            fontSize: "24px",
          },
        })}
      >
        {props.title}
      </h2>
      <div
        css={css({
          minHeight: "140px",
          "@media (max-width:768px)": {
            minHeight: "auto",
            display: "flex",
            alignSelf: "flex-start",
            justifyContent: "flex-start",
          },
        })}
      >
        <ul
          css={css({
            color: colors.whiteText,
            fontSize: "20px",
            margin: "0",
            width: "100%",
            textAlign: "left",
            paddingLeft: "25px",
            height: "auto",
            "@media (max-width:768px)": {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              alignSelf: "center",
              width: "100%",
            },
          })}
        >
          {props.items.map((item) => (
            <li style={{ marginBottom: "4px", maxWidth: "295px" }}>{item}</li>
          ))}
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "20px",
        }}
      >
        <button
          onClick={handleCalendry({ path: props.calendlyPath })}
          css={css(boxStyle, {
            fontSize: "20px",
            marginTop: "20px",
            width: "320px",
            height: "64px",
          })}
        >
          Book Your Intro Meeting
        </button>
      </div>
    </div>
  );
};
export default Services;

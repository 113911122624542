/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { useLayoutEffect } from "react";
import { css } from "@emotion/react";
import "../App.css";
import { colors } from "../theme";
import Visiontopleft from "../icons/VisionTopLeft";
import Logo from "./img/Logo.png";
import { handleCalendry } from "../utils/Calendry";
import Animation from "./Animation";
import { Link } from "react-scroll";
const Header = () => {
  const [loaded, setLoaded] = useState(false);
  useLayoutEffect(() => {
    setLoaded(true);
  });
  return (
    <header
      style={{
        flexDirection: "column",
        backgroundColor: colors.background,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        css={css({
          alignSelf: "flex-start",
          paddingTop: "13px",
          paddingBottom: "13px",
          paddingRight: "20px",
          paddingLeft: "40px",
          position: "fixed",
          top: 0,
          backgroundColor: colors.background,
          zIndex: "1",
          borderBottomRightRadius: "10px",
          "@media (max-width:768px)": {
            width: "100%",
            textAlign: "center",
            borderBottomRightRadius: "13px",
            borderBottomLeftRadius: "13px",
          },
        })}
      >
        <Visiontopleft />
      </div>
      <div
        css={css({
          "@media (max-width:480px)": {
            paddingTop: "210px",
          },
        })}
      >
        <div
          css={css({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "170px",
            "@media (max-width:480px)": {
              paddingTop: "20px",
            },
          })}
        >
          <Visiontopleft
            src={Logo}
            alt="logo"
            width="600px"
            height="120px"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              maxWidth: "80%",
              height: "auto",
              opacity: loaded ? "1" : "0",
              transform: loaded ? "translateY(0)" : "translateY(50%)",
              transition: "2s cubic-bezier(0.83, 0, 0.17, 1) 0.2s",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "25px",
            paddingTop: "30px",
            transform: loaded ? "translateY(0)" : "translateY(50%)",
            opacity: loaded ? "1" : "0",
            transition: "2s cubic-bezier(0.83, 0, 0.17, 1) 2s",
          }}
        >
          <button
            onClick={handleCalendry()}
            className="btn"
            css={css({
              zIndex: "1",
              width: "150px",
              height: "60px",
              fontWeight: "400",
              borderColor: colors.primary,
              color: colors.whiteText,
              backgroundColor: colors.primary,
              borderRadius: "7px",
              borderWidth: "1px",
              fontSize: "16px",
              cursor: "pointer",
              transition: "background-color 0.2s ease-out",
              ":hover": {
                backgroundColor: colors.dark,
              },
              "@media (max-width:480px)": {
                fontSize: "15px",
              },
            })}
          >
            Book Meeting
          </button>

          <Link
            activeClass="active"
            to="services"
            spy={false}
            smooth={true}
            offset={0}
            duration={700}
            href="services"
          >
            <button
              css={css({
                fontWeight: "400",
                color: colors.primary,
                backgroundColor: "transparent",
                zIndex: "1",
                width: "150px",
                height: "60px",
                borderRadius: "7px",
                borderColor: colors.primary,
                borderWidth: "1px",
                fontSize: "16px",
                cursor: "pointer",
                transition: "background-color 0.2s ease-out",
                ":hover": {
                  backgroundColor: colors.primary,
                  color: colors.whiteText,
                },
                "@media (max-width:480px)": {
                  fontSize: "15px",
                },
              })}
            >
              Our Services
            </button>
          </Link>
        </div>
      </div>

      <Animation />
    </header>
  );
};
export default Header;

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { colors } from "../theme";
export const InputField = (props) => {
  return (
    <div style={{ position: "relative", margin: "6px" }}>
      <input
        placeholder={props.placeholder}
        css={css({
          padding: "10px 50px 14px 54px",
          backgroundColor: colors.background,
          borderRadius: "8px",
          fontSize: "20px",
          fontWeight: "normal",
          outline: "none",
          boxShadow: "none",
          border: `1px solid ${colors.lighter}`,
          "::placeholder": {
            fontSize: "16px",
          },
        })}
      />
      <div
        css={css({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: "6px",
          width: "38px",
          height: "auto",
          "@media (max-width:768px)": {
            top: "58%",
          },
        })}
      >
        {props.icon}
      </div>
      {props.children}
    </div>
  );
};

import React from "react";

function GroupWave(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1600"
      height="827"
      fill="none"
      viewBox="0 0 1600 827"
      {...props}
    >
      <path
        fill="#006180"
        d="M-62.493 219.145l-67.15-12.081a417.003 417.003 0 00-301.658 61.142c-101.484 66.195-169.159 173.258-185.414 293.327l-34.164 252.358H2226.61V573.378c0-46.431-8.87-92.435-26.12-135.539a364.678 364.678 0 00-125.14-160.171l-82.27-59.372c-126.37-91.206-294.68-99.183-429.12-20.336-108.46 63.611-240.8 71.528-356.06 21.301l-189.57-82.6c-200.068-87.177-427.075-88.798-628.368-4.487l-120.711 50.56a588.82 588.82 0 01-331.744 36.411z"
        opacity="0.05"
      ></path>
      <path
        fill="#006180"
        d="M-367.594 714.394l-27.707 7.975C-517.46 757.532-613.597 852.011-650.879 973.54H2226.61v-79.752a148.709 148.709 0 00-103.58-141.694l-143.35-45.656a707.853 707.853 0 00-392.17-10.801l-23.45 6.068a791.77 791.77 0 01-347.91 11.014l-325.876-62.662a999.997 999.997 0 00-370.589-1.333L238.321 700.73a999.993 999.993 0 01-260.906 13.52l-192.244-15.264a429.398 429.398 0 00-152.765 15.408z"
        opacity="0.1"
      ></path>
      <path
        fill="#006180"
        d="M1706 557.138l-53.7 22.466c-109.06 45.631-231.85 45.744-341.01.313l-78.82-32.805a647.324 647.324 0 00-413.528-28.374l-217.961 57.374a814.561 814.561 0 01-364.444 11.542L39.407 548.91a276.047 276.047 0 00-320.7 202.566l-7.301 28.589H2239.36c42.54-45.1 23.1-119.184-36.11-137.577l-304.31-94.527a282.662 282.662 0 00-192.94 9.177z"
        opacity="0.3"
      ></path>
      <path
        fill="#006180"
        d="M-521.256 559.949l-3.001 2.371A289.98 289.98 0 00-624.78 715.505l-26.099 98.386H1797.84l-59.75-27.091a476.94 476.94 0 01-108.11-67.745l-46.68-38.838c-106.86-88.903-254.05-111.582-382.71-58.965a375.926 375.926 0 01-261.367 8.616L567.28 505.661a426.317 426.317 0 00-342.022 31.668l-81.549 45.288c-99.841 55.448-223.137 45.965-313.324-24.1-103.586-80.474-248.714-79.883-351.641 1.432z"
        opacity="0.5"
      ></path>
      <path
        fill="#006180"
        d="M-418.626 702.292l-77.968 21.223a291.26 291.26 0 00-154.285 103.352H2226.61c0-28.049-19.87-52.168-47.4-57.536l-223.06-43.495a334.423 334.423 0 00-88.6-5.278l-520.8 38.384a579.042 579.042 0 01-292.11-54.967 579.053 579.053 0 00-342.918-48.956l-435.125 71.093a924.513 924.513 0 01-347.326-9.409l-94.32-20.708a531.507 531.507 0 00-253.577 6.297z"
      ></path>
    </svg>
  );
}

export default GroupWave;

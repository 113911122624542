import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 320 321"
      {...props}
    >
      <g clipPath="url(#clip0_24_534)">
        <path
          fill="#fff"
          d="M0 21.58C0 9.796 9.551.245 21.333.245h277.334C310.449.246 320 9.797 320 21.58v277.334c0 11.782-9.551 21.333-21.333 21.333H21.333C9.551 320.246 0 310.695 0 298.913V21.579z"
        ></path>
        <g filter="url(#filter0_d_24_534)">
          <rect
            width="275.174"
            height="275.174"
            x="54.023"
            y="-61.307"
            fill="#628000"
            fillOpacity="0.3"
            rx="11.144"
            shapeRendering="crispEdges"
          ></rect>
        </g>
        <g filter="url(#filter1_d_24_534)">
          <rect
            width="254.647"
            height="254.648"
            x="-73.301"
            y="86.543"
            fill="#800062"
            fillOpacity="0.3"
            rx="119.107"
            shapeRendering="crispEdges"
          ></rect>
        </g>
        <g filter="url(#filter2_d_24_534)">
          <rect
            width="291.429"
            height="291.429"
            x="101.836"
            y="76.369"
            fill="#FF6119"
            fillOpacity="0.3"
            rx="124.858"
            shapeRendering="crispEdges"
          ></rect>
        </g>
        <g filter="url(#filter3_d_24_534)">
          <path
            fill="#428000"
            d="M228.906 114.422c1.906-5.867 10.206-5.867 12.112 0l22.403 68.947a6.368 6.368 0 006.056 4.401h72.496c6.169 0 8.734 7.894 3.743 11.52l-58.65 42.612a6.368 6.368 0 00-2.313 7.12l22.402 68.947c1.906 5.867-4.809 10.746-9.8 7.12l-58.65-42.612a6.37 6.37 0 00-7.486 0l-58.65 42.612c-4.991 3.626-11.706-1.253-9.8-7.12l22.402-68.947a6.368 6.368 0 00-2.313-7.12l-58.65-42.612c-4.991-3.626-2.426-11.52 3.743-11.52h72.496a6.368 6.368 0 006.056-4.401l22.403-68.947z"
          ></path>
        </g>
        <g filter="url(#filter4_d_24_534)">
          <path
            fill="#006180"
            d="M43.452-40.018c2.328-4.762 9.115-4.762 11.443 0L70.368-8.365a6.368 6.368 0 008.306 3.023l32.199-14.302c4.844-2.151 10.043 2.211 8.765 7.355l-8.492 34.194a6.368 6.368 0 004.419 7.655l33.859 9.741c5.094 1.466 6.272 8.15 1.987 11.269l-28.485 20.735a6.368 6.368 0 00-1.535 8.704l19.675 29.227c2.96 4.397-.433 10.274-5.721 9.909l-35.148-2.426a6.368 6.368 0 00-6.772 5.682l-3.714 35.036c-.559 5.271-6.936 7.592-10.752 3.913l-25.366-24.451a6.368 6.368 0 00-8.84 0L19.389 161.35c-3.816 3.679-10.194 1.358-10.752-3.913L4.92 122.401a6.368 6.368 0 00-6.77-5.682L-37 119.145c-5.287.365-8.68-5.512-5.72-9.909l19.675-29.227a6.368 6.368 0 00-1.535-8.704L-53.064 50.57c-4.285-3.12-3.107-9.803 1.987-11.269l33.859-9.741a6.368 6.368 0 004.42-7.655l-8.494-34.194c-1.277-5.144 3.922-9.506 8.766-7.355L19.673-5.342a6.368 6.368 0 008.306-3.023l15.473-31.653z"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_24_534"
          width="278.729"
          height="281.396"
          x="54.023"
          y="-61.307"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_24_534"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_24_534"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_24_534"
          width="258.203"
          height="260.87"
          x="-73.301"
          y="86.543"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_24_534"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_24_534"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_d_24_534"
          width="294.984"
          height="297.651"
          x="101.836"
          y="76.369"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_24_534"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_24_534"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter3_d_24_534"
          width="230.339"
          height="222.535"
          x="121.57"
          y="110.022"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_24_534"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_24_534"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter4_d_24_534"
          width="213.277"
          height="212.954"
          x="-55.688"
          y="-43.59"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="1.778" dy="4.444"></feOffset>
          <feGaussianBlur stdDeviation="0.889"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_24_534"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_24_534"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0_24_534">
          <path
            fill="#fff"
            d="M0 21.58C0 9.796 9.551.245 21.333.245h277.334C310.449.246 320 9.797 320 21.58v277.334c0 11.782-9.551 21.333-21.333 21.333H21.333C9.551 320.246 0 310.695 0 298.913V21.579z"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;

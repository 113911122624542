export const handleCalendry = (data) => (e) => {
  e.preventDefault();

  const options = data || {};
  // Disabling custom calendly pages
  options.path = "";

  window.Calendly.initPopupWidget({
    url: `https://calendly.com/visionforge${
      options.path || ""
    }?primary_color=006180`,
  });
};
